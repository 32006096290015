import { FC, useState } from "react";
import { FaX } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import { CheckoutForm } from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useMethodStore } from "../../store/methodStore";
import {
  StripePublicKeyProd,
  StripePublicKeyTest,
  SubscriptionModalProps,
} from "../../utils/types/props";
import { useAccountInfoStore } from "../../store/accountStore";

loadStripe.setLoadParameters({ advancedFraudSignals: false });

export const SubscriptionModal: FC<SubscriptionModalProps> = ({
  handleHideModal,
  createIntentBody,
}) => {
  const { amount, currency, inv } = createIntentBody;
  const { methods } = useMethodStore();
  const { account } = useAccountInfoStore();

  const [methodId, setMethodId] = useState<string>(
    methods?.find((m) => m.default)?.id?.toString() || ""
  );
  const stripePromise = loadStripe(
    account!.liveMode ? StripePublicKeyProd : StripePublicKeyTest
  );

  return (
    <div>
      <div className="flex justify-end">
        <button
          onClick={() => handleHideModal(false)}
          className="text-gray-500 px-2"
        >
          <FaX />
        </button>
      </div>
      <div className="px-3">
        <h2 className="text-3xl text-blue-500 text-center">Invoice payment</h2>
        <div className="bg-gray-100 rounded-md shadow p-3 mt-4 text-gray-700">
          <div className="flex justify-between">
            <p>Invoice</p>
            <p>{inv}</p>
          </div>
          <div className="flex justify-between mt-2">
            <p>Amount</p>
            <p>
              {amount.toFixed(2)} {currency}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-3 px-3">
        {methods && methods.length > 0 ? (
          <div className="p-4 px-0">
            <select
              onChange={(e) => {
                setMethodId(e.target.value);
              }}
              value={methodId}
              className="border border-gray-300 rounded shadow p-1.5 w-full"
            >
              <option value={""}>Choose a payment method</option>
              {methods.map((method) => {
                return (
                  <option
                    selected={methods.length == 1 ? true : false}
                    key={method.id}
                    value={method.id?.toString()}
                  >
                    {method.displayName}
                  </option>
                );
              })}
            </select>
            <Elements stripe={stripePromise}>
              <CheckoutForm
                handleClose={handleHideModal}
                createIntentBody={createIntentBody}
                methodId={methodId}
              />
            </Elements>
          </div>
        ) : (
          <div className="flex flex-col items-center gap-1.5">
            <p className="text-gray-700">No payment method have been set up.</p>
            <NavLink to={"/payment-method"} className="button-blue">
              Go to payment methods
            </NavLink>
          </div>
        )}
      </div>{" "}
    </div>
  );
};
