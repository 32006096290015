import Swal from "sweetalert2";
import { ModalCard } from "./ModalCard";
import { Outlet } from "react-router";
import { TbLogout } from "react-icons/tb";
import { useEffect, useState } from "react";
import { client } from "@hey-api/client-fetch";
import { swalStyle } from "../utils/functions";
import { FaRegCreditCard } from "react-icons/fa6";
import { SubscriptionService } from "../api-client";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { useMethodStore } from "../store/methodStore";
import { NavLink, useNavigate } from "react-router-dom";
import { SwitchAccountModal } from "./SwitchAccountModal";
import { useAccountInfoStore } from "../store/accountStore";
import { removeTokenInterceptor } from "../utils/interceptor";

export const WithNav = () => {
  const navigate = useNavigate();
  const { setMethods } = useMethodStore();
  const { account, setAccount, accounts, setAccounts } = useAccountInfoStore();

  const [showCard, setShowCard] = useState<boolean>(false);
  const [animateCard, setAnimateCard] = useState<boolean>(false);

  useEffect(() => {
    if (!account) {
      const accountData = localStorage.getItem("account_subscription");
      const accountsData = localStorage.getItem("accounts_subscription");
      if (accountData) {
        setAccount(JSON.parse(accountData));
        if (accountsData) {
          setAccounts(JSON.parse(accountsData));
        }

        client.interceptors.request.use((request, options) => {
          request.headers.set(
            "Authorization",
            localStorage.getItem("token_subscription") as string
          );
          return request;
        });
        getMethods();
      } else {
        navigate("/login");
      }
    } else {
      getMethods();
    }
  }, [account]);

  const getMethods = async (): Promise<void> => {
    if (!account?.id) return;
    try {
      const response = await SubscriptionService.accountPaymentMethods({
        path: { accountId: account.id },
      });
      setMethods(response.data?.results);
    } catch (error) {}
  };

  const confirmLogout = (): void => {
    Swal.fire({
      title: `Are you sure you want to log out?`,
      showCancelButton: true,
      position: "top",
      cancelButtonText: "Confirm",
      confirmButtonText: "Cancel",
      allowEnterKey: false,
      ...swalStyle,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.close();
      } else if (result.isDismissed) {
        removeTokenInterceptor();
        setAccount(undefined);
        setAccounts(undefined);
        navigate("/login");
      }
    });
  };

  const handleClose = (): void => {
    setAnimateCard(false);
    setTimeout(() => {
      setShowCard(false);
    }, 700);
  };

  useEffect(() => {
    if (showCard) {
      setAnimateCard(true);
    }
  }, [showCard]);

  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      {showCard && (
        <ModalCard
          showCard={showCard}
          handleHideModal={handleClose}
          animateCard={animateCard}
        >
          <SwitchAccountModal handleHideModal={handleClose} />
        </ModalCard>
      )}
      <div className="p-4 px-2.5 md:w-[230px]  md:h-screen bg-[#243f60] shadow-r-md">
        <div className="flex flex-col gap-4 mb-6">
          <p className="text-xl font-normal text-white text-center">
            {account?.name}
          </p>
          {account?.balance?.outstanding || account?.balance?.overdue ? (
            <div className="flex flex-col bg-[#3d6aa1] rounded p-2 w-full shadow">
              {!!account?.balance?.outstanding && (
                <div className="flex gap-2 justify-between items-center text-sm text-gray-600 mb-1">
                  <span className="text-gray-100">Outstanding:</span>
                  <span className="text-sm font-medium text-white">
                    {account?.balance?.outstanding?.toFixed(2)}{" "}
                    {account?.currency}
                  </span>
                </div>
              )}

              {!!account?.balance?.overdue && (
                <div className="flex gap-3 justify-between items-center text-sm text-gray-600">
                  <span className="text-gray-100">Overdue:</span>
                  <span className="text-sm font-medium text-white">
                    {account?.balance?.overdue?.toFixed(2)} {account?.currency}
                  </span>
                </div>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="flex flex-col gap-2 mt-8">
          <NavLink
            to="/invoices"
            className={({ isActive }) =>
              `flex gap-2 items-center border-l-[6px] border-l-[#3d6aa1] ${
                isActive
                  ? " !border-[#5494e1] bg-[#355d8e] shadow"
                  : " !border-l-[#243f60] hover:!border-l-[#335b8b] hover:bg-[#2a4b72] hover:shadow"
              } rounded w-full p-2 py-1.5 text-white`
            }
          >
            <LiaFileInvoiceSolid size={21} />
            Invoices
          </NavLink>
          <NavLink
            to="/payment-method"
            className={({ isActive }) =>
              `flex gap-2 items-center border-l-[6px] border-l-[#3d6aa1] ${
                isActive
                  ? " !border-[#5494e1] bg-[#355d8e] shadow"
                  : " !border-l-[#243f60] hover:!border-l-[#335b8b] hover:bg-[#2a4b72] hover:shadow"
              } rounded w-full p-2 py-1.5 text-white`
            }
          >
            <FaRegCreditCard size={21} />
            Payment methods
          </NavLink>
          <div></div>
          {accounts && (
            <button
              className="flex gap-2 items-center rounded text-white w-full py-1.5 pl-2 pr-1.5 border-l-[6px] border-l-[#243f60] hover:!border-l-[#335b8b] hover:bg-[#2a4b72] hover:shadow"
              onClick={() => setShowCard(true)}
            >
              <AiOutlineUserSwitch size={23} /> <p>Switch account</p>
            </button>
          )}
          <button
            className="flex gap-2 items-center rounded text-white w-full py-1.5 pl-2 pr-1.5 border-l-[6px] border-l-[#243f60] hover:!border-l-[#335b8b] hover:bg-[#2a4b72] hover:shadow"
            onClick={confirmLogout}
          >
            <TbLogout size={23} /> <p>Log out</p>
          </button>
        </div>
      </div>
      {account && (
        <div className="flex flex-1 bg-indigo-50 p-2 md:w-[calc(100vw-230px)] pt-8 md:pt-2">
          <Outlet />
        </div>
      )}
    </div>
  );
};
