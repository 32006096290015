import { client } from "@hey-api/client-fetch";

export const addTokenInterceptor = (token: string) => {
  client.interceptors.request.use((request) => {
    request.headers.set("Authorization", token);
    return request;
  });
};

export const removeTokenInterceptor = () => {
  client.interceptors.request.use((request) => {
    request.headers.delete("Authorization");
    localStorage.removeItem("account_subscription");
    localStorage.removeItem("accounts_subscription");
    localStorage.removeItem("token_subscription");
    return request;
  });
};
