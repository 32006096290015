import {
  Column,
  useTable,
  useSortBy,
  usePagination,
  useFlexLayout,
  useResizeColumns,
} from "react-table";
import Swal from "sweetalert2";
import amex from "../../images/amex.png";
import visa from "../../images/visa.png";
import bank from "../../images/bank.png";
import { MdDelete } from "react-icons/md";
import { useRef, useMemo, FC } from "react";
import mastercard from "../../images/mastercard.png";
import creditCard from "../../images/credit-card.png";
import { MethodTableProps } from "../../utils/types/props";
import { useAccountInfoStore } from "../../store/accountStore";
import TopLoadingBar, { LoadingBarRef } from "react-top-loading-bar";
import { AccountPaymentMethod, SubscriptionService } from "../../api-client";
import { handleError, handleSuccess, swalStyle } from "../../utils/functions";

export const MethodTable: FC<MethodTableProps> = ({ data, setData }) => {
  const { account } = useAccountInfoStore();

  const barRef = useRef<LoadingBarRef>(null);

  const startLoading = (): void => {
    barRef.current?.continuousStart();
  };

  const finishLoading = (): void => {
    barRef.current?.complete();
  };

  interface AccountPaymentMethodTable extends AccountPaymentMethod {
    delete: string;
  }

  const methodsColumns: Column<AccountPaymentMethodTable>[] = useMemo(
    () => [
      { Header: "Name", accessor: "name", width: 300 },
      { Header: "Expiring Date", accessor: "expiry", width: 95 },
      { Header: "Default", accessor: "default", width: 50 },
      { Header: "", accessor: "delete", width: 40 },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: methodsColumns as Column<AccountPaymentMethod>[],
        data,
      },
      useFlexLayout,
      useResizeColumns,
      useSortBy,
      usePagination
    );

  const getIcon = (type: string): string => {
    switch (type) {
      case "visa":
        return visa;
      case "amex":
        return amex;
      case "mastercard":
        return mastercard;
      case "bank":
        return bank;
      default:
        return creditCard;
    }
  };

  const handleSetDefault = async (
    methodId: string | undefined | null,
    isDefault: boolean | undefined
  ): Promise<void> => {
    if (isDefault) return;
    if (!methodId || !account?.id) {
      handleError("Could not set default payment method.");
      return;
    }

    try {
      startLoading();
      const response = await SubscriptionService.accountPaymentMethodSetDefault(
        {
          path: {
            accountId: account?.id,
            methodId,
          },
        }
      );
      if (response.error) {
        handleError(response.error);
        return;
      }

      const tempData = data.map((method) => {
        if (method.id === methodId) {
          method.default = true;
        } else {
          method.default = false;
        }
        return method;
      });
      setData(tempData);
    } catch (err: any) {
      if (err.response?.data) {
        const { error, errors, warning } = err.response.data;

        if (error || errors || warning) {
          handleError(err.response.data);
          return;
        }
      } else {
        handleError(err.message);
      }
    } finally {
      finishLoading();
    }
  };

  const handleDeleteCard = async (
    methodId: string | undefined | null
  ): Promise<void> => {
    if (!methodId || !account?.id) {
      handleError("Could not remove payment method.");
      return;
    }

    try {
      startLoading();

      const response = await SubscriptionService.accountPaymentMethodDelete({
        path: {
          accountId: account?.id,
          methodId,
        },
      });
      if (response.error) {
        handleError(response.error);
        return;
      }
      const tempData = data.filter((method) => method.id !== methodId);
      setData(tempData);
      handleSuccess("Payment method removed successfully.");
    } catch (err: any) {
      if (err.response?.data) {
        const { error, errors, warning } = err.response.data;

        if (error || errors || warning) {
          handleError(err.response.data);
          return;
        }
      } else {
        handleError(err.message);
      }
    } finally {
      finishLoading();
    }
  };

  const confirmDeleteCard = (method: AccountPaymentMethod): void => {
    Swal.fire({
      title: `Are you sure you want to remove ${method.displayName}?`,
      showCancelButton: true,
      position: "top",
      cancelButtonText: "Confirm",
      confirmButtonText: "Cancel",
      allowEnterKey: false,
      ...swalStyle,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.close();
      } else if (result.isDismissed) {
        handleDeleteCard(method.id);
      }
    });
  };

  return (
    <div className="w-full px-1 pb-2 max-h-[90vh] overflow-y-auto bg-white rounded shadow">
      <TopLoadingBar color="#03bf5f" ref={barRef} height={3} />
      <table {...getTableProps()} className="w-full">
        <thead className="sticky -top-0 z-[4]">
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="bg-white pt-1"
            >
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  {...column.getHeaderProps()}
                  className={`${
                    columnIndex === 0
                      ? "rounded-s-md"
                      : columnIndex === methodsColumns.length - 1
                      ? "rounded-e-md"
                      : ""
                  } ${columnIndex} text-left p-2 text-xs font-medium text-gray-500 bg-gray-100 relative`}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="bg-white">
          {rows.length > 0 ? (
            rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    const columnId = cell.column.id;

                    const isNameColumn = columnId === "name";
                    const isDefaultColumn = columnId === "default";
                    const isDeleteColumn = columnId === "delete";
                    const method = row.original;

                    return (
                      <td
                        {...cell.getCellProps()}
                        className="p-3 whitespace-nowrap overflow-hidden border-b border-gray-300 text-sm"
                        key={columnId}
                      >
                        {isNameColumn ? (
                          cell.value && (
                            <div className="flex gap-3 items-center w-[300px]">
                              <img
                                src={getIcon(cell.value)}
                                className="w-9 h-6 rounded-sm"
                              />
                              <p>{method.displayName}</p>
                            </div>
                          )
                        ) : isDefaultColumn ? (
                          <div className="flex items-center justify-between h-full">
                            <button
                              className={
                                `${
                                  method.default
                                    ? "bg-gray-600 text-white"
                                    : "bg-gray-200 text-black"
                                }` + " py-1 px-2 rounded-full text-xs"
                              }
                              onClick={() =>
                                handleSetDefault(method.id, method.default)
                              }
                            >
                              {method.default ? "Default" : "Set Default"}
                            </button>
                          </div>
                        ) : isDeleteColumn ? (
                          <div className="flex items-center justify-end h-full">
                            <button onClick={() => confirmDeleteCard(method)}>
                              <MdDelete
                                className="text-red-500 hover:text-red-600"
                                size={18}
                              />
                            </button>
                          </div>
                        ) : (
                          cell.value
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr className="w-full border-b flex items-center justify-center p-2 text-lg text-gray-500">
              <td>
                <p>No payment method have been created yet.</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
