import { create } from "zustand";
import { AccountInfo } from "../api-client";

export interface AccountInfoInterface {
  account: AccountInfo | undefined;
  setAccount: (value: AccountInfo | undefined) => void;
  accounts: AccountInfo[] | undefined;
  setAccounts: (value: AccountInfo[] | undefined) => void;
}

export const useAccountInfoStore = create<AccountInfoInterface>((set) => ({
  account: undefined,
  setAccount: (value: AccountInfo | undefined) => set({ account: value }),
  accounts: undefined,
  setAccounts: (value: AccountInfo[] | undefined) => set({ accounts: value }),
}));
