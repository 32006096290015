import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { FC } from "react";
import { loadStripe } from "@stripe/stripe-js/pure";
import { SubscriptionService } from "../../api-client";
import {
  EmbeddedMethodProps,
  StripePublicKeyProd,
  StripePublicKeyTest,
} from "../../utils/types/props";
import { useAccountInfoStore } from "../../store/accountStore";

export const EmbeddedMethod: FC<EmbeddedMethodProps> = ({ handleClose }) => {
  loadStripe.setLoadParameters({ advancedFraudSignals: false });
  const { account } = useAccountInfoStore();

  const fetchClientSecret = async (): Promise<string> => {
    if (!account?.id) {
      return "";
    }
    const res = await SubscriptionService.accountPaymentMethodCreate({
      path: { accountId: account.id },
    });

    return res.data?.data?.clientSecret || "";
  };

  const onComplete = () => {
    handleClose(true);
  };

  const options = { fetchClientSecret, onComplete };
  const stripePromise = loadStripe(
    account!.liveMode ? StripePublicKeyProd : StripePublicKeyTest
  );

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout className="bg-white" />
      </EmbeddedCheckoutProvider>
    </div>
  );
};
