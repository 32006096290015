import { FC, useState, useEffect } from "react";

export type SubscriptionModalProps = {
  handleHideModal: (refresh: boolean) => void;
  children: any;
  showCard: boolean;
  animateCard: boolean;
};

export const ModalCard: FC<SubscriptionModalProps> = ({
  handleHideModal,
  children,
  showCard,
  animateCard,
}) => {
  return (
    <div
      className={`fixed inset-0 w-full h-full ${!showCard ? "-z-10" : "z-10"}`}
      onClick={() => handleHideModal(false)}
    >
      <div
        className={`absolute right-0 top-0 transition-transform !duration-700 ease-in-out ${
          animateCard ? "translate-x-0" : "translate-x-full"
        } w-[310px] sm:w-[370px] bg-white rounded-s border-l h-screen shadow-l-md p-2`}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};
