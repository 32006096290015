import {
  AccountInvoiceListResults,
  SubscriptionService,
} from "../../api-client";
import { ModalCard } from "../ModalCard";
import { useState, useRef, useEffect } from "react";
import { handleError } from "../../utils/functions";
import { SubscriptionModal } from "./SubscriptionModal";
import { SubscriptionTable } from "./SubscriptionTable";
import { useAccountInfoStore } from "../../store/accountStore";
import type { CreateIntentBodyType } from "../../utils/types/props";
import TopLoadingBar, { LoadingBarRef } from "react-top-loading-bar";

export const Subscription = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [invoices, setInvoices] = useState<AccountInvoiceListResults>();
  const [createIntentBody, setCreateIntentBody] =
    useState<CreateIntentBodyType>();
  const [showCard, setShowCard] = useState<boolean>(false);
  const [animateCard, setAnimateCard] = useState<boolean>(false);
  const { account } = useAccountInfoStore();

  const barRef = useRef<LoadingBarRef>(null);

  const startLoading = (): void => {
    barRef.current?.continuousStart();
  };

  const finishLoading = (): void => {
    barRef.current?.complete();
  };

  const getInvoices = async (): Promise<void> => {
    if (!account?.id) return;
    try {
      startLoading();
      setLoading(true);

      const response = await SubscriptionService.accountListInvoices({
        path: { accountId: account?.id },
      });
      if (response.error) {
        handleError(response.error);
        return;
      }
      setInvoices(response.data);
    } catch (error: any) {
      handleError(error.message);
    } finally {
      finishLoading();
      setLoading(false);
    }
  };

  const handleShowModal = (
    amount: number,
    reference: string,
    inv: string
  ): void => {
    if (!amount || reference === "") {
      handleError("");
      return;
    }

    setCreateIntentBody({
      amount,
      currency: account?.currency || "",
      reference,
      inv,
    });

    setShowCard(true);
  };

  const handleClose = (refresh: boolean): void => {
    setAnimateCard(false);
    setTimeout(() => {
      setShowCard(false);
      if (refresh) {
        getInvoices();
      }
    }, 700);
  };

  useEffect(() => {
    if (showCard) {
      setAnimateCard(true);
    }
  }, [showCard]);

  useEffect(() => {
    document.title = "Invoices";
    getInvoices();
  }, [account]);

  return (
    <div className="w-full">
      <TopLoadingBar color="#03bf5f" ref={barRef} height={3} />
      {showCard && (
        <ModalCard
          showCard={showCard}
          handleHideModal={handleClose}
          animateCard={animateCard}
        >
          <SubscriptionModal
            handleHideModal={handleClose}
            createIntentBody={createIntentBody as CreateIntentBodyType}
          />
        </ModalCard>
      )}
      <div className="w-full">
        <div className="flex justify-between mb-2 px-0.5">
          <h1 className="text-gray-500 text-3xl pt-2 pb-2">Invoices</h1>
        </div>
        <SubscriptionTable
          handleShowModal={handleShowModal}
          loading={loading}
          data={invoices?.results || []}
        />
      </div>
    </div>
  );
};
