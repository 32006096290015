import "animate.css";
import { WithNav } from "./components/WithNav";
import { LoginRequest } from "./components/LoginRequest";
import { useAccountInfoStore } from "./store/accountStore";
import { Methods } from "./components/paymentMethod/Methods";
import { createClient, client } from "@hey-api/client-fetch";
import { Subscription } from "./components/subscription/Subscription";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

function App() {
  const { setAccount, setAccounts } = useAccountInfoStore();
  const baseUrl =
    process.env.REACT_APP_API_ENDPOINT || "https://api-billing.tradepeg.com";

  createClient({
    baseUrl: baseUrl,
  });

  client.interceptors.response.use((response, request, options) => {
    if (response.status === 401) {
      localStorage.removeItem("token_subscription");
      localStorage.removeItem("account_subscription");
      localStorage.removeItem("accounts_subscription");

      setAccount(undefined);
      setAccounts(undefined);
      window.location.href = "/login";
    }
    return response;
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginRequest />} />
        <Route path="/" element={<WithNav />}>
          <Route path="/" element={<Navigate to="/invoices" />} />
          <Route path="/invoices" element={<Subscription />} />
          <Route path="/payment-method" element={<Methods />} />
        </Route>
        <Route path="*" element={<Navigate to="/invoices" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
