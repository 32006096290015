import { create } from "zustand";
import { AccountPaymentMethod } from "../api-client";

export interface MethodInterface {
  methods: AccountPaymentMethod[] | undefined | null;
  setMethods: (value: AccountPaymentMethod[] | undefined | null) => void;
}

export const useMethodStore = create<MethodInterface>((set) => ({
  methods: undefined,
  setMethods: (value: AccountPaymentMethod[] | undefined | null) =>
    set({ methods: value }),
}));
