import { handleSuccess } from "../../utils/functions";
import { SubscriptionService } from "../../api-client";
import { useState, FC, useEffect, FormEvent } from "react";
import { CheckoutFormProps } from "../../utils/types/props";
import { useAccountInfoStore } from "../../store/accountStore";
import { useStripe, useElements } from "@stripe/react-stripe-js";

export const CheckoutForm: FC<CheckoutFormProps> = ({
  handleClose,
  createIntentBody,
  methodId,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { account, setAccount } = useAccountInfoStore();

  const [loading, setLoading] = useState<boolean>(false);
  const [idempotencyKey, setIdempotencyKey] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const handlePayment = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    try {
      if (!account?.id || stripe == null || !methodId || !idempotencyKey)
        return;
      setLoading(true);
      const res = await SubscriptionService.accountCreateInvoicePayment({
        path: {
          accountId: account.id,
          invoiceId: createIntentBody.reference,
        },
        body: {
          methodId,
          amount: createIntentBody.amount,
          idempotencyKey,
        },
      });

      if (res.error) {
        setErrorMessage(res.error.error!);
        updateId();
        return;
      }

      if (!res.data?.data) {
        setErrorMessage("Something went wrong.");
        updateId();

        return;
      }

      const { clientSecret, requiresAction, complete } = res.data?.data;
      if (complete) {
        handleSuccess("Payment successful");
        updateAccount();
        /*         handleClose(true); */
        return;
      }

      if (!clientSecret) {
        setErrorMessage("Something went wrong.");
        updateId();

        return;
      }

      if (requiresAction) {
        const { error } = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          setErrorMessage(error.message);
          updateId();

          return;
        } else {
          handleSuccess("Payment successful");
          updateAccount();
          handleClose(true);
        }
      }
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const updateAccount = async (): Promise<void> => {
    try {
      const response = await SubscriptionService.accountsList();

      const accountResult = response.data?.results?.find(
        (accountResult) => accountResult.id === account!.id
      );
      setAccount(accountResult);
    } catch (error) {}
  };

  const updateId = (): void => {
    setIdempotencyKey(
      `idempotencyKey_${Math.random()
        .toString(16)
        .slice(2)}_${Date.now().toString()}`
    );
  };

  useEffect(() => {
    updateId();
  }, []);

  return (
    <form onSubmit={(e) => handlePayment(e)}>
      <button
        className="button-pay w-full mt-3 text-base !py-1 disabled:bg-gray-500"
        type="submit"
        disabled={
          !stripe ||
          !elements ||
          methodId === undefined ||
          methodId === "" ||
          loading
        }
      >
        {loading ? "Processing..." : " Pay Now "}
      </button>
      {errorMessage && (
        <div className="text-red-500 mt-2 text-sm font-semibold">
          {errorMessage}
        </div>
      )}
    </form>
  );
};
