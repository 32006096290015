import { MethodTable } from "./MethodTable";
import { MethodModal } from "./MethodModal";
import { handleError } from "../../utils/functions";
import { useEffect, useRef, useState } from "react";
import { SubscriptionService } from "../../api-client";
import { useMethodStore } from "../../store/methodStore";
import { useAccountInfoStore } from "../../store/accountStore";
import TopLoadingBar, { LoadingBarRef } from "react-top-loading-bar";
import { ModalCard } from "../ModalCard";

export const Methods = () => {
  const { account } = useAccountInfoStore();
  const { methods, setMethods } = useMethodStore();

  const [showCard, setShowCard] = useState<boolean>(false);
  const [animateCard, setAnimateCard] = useState<boolean>(false);

  const barRef = useRef<LoadingBarRef>(null);
  const startLoading = () => {
    barRef.current?.continuousStart();
  };

  const finishLoading = (): void => {
    barRef.current?.complete();
  };

  const getMethods = async (): Promise<void> => {
    if (!account?.id) return;
    try {
      startLoading();

      const response = await SubscriptionService.accountPaymentMethods({
        path: { accountId: account.id },
      });
      if (response.error) {
        handleError(response.error);
      }
      setMethods(response.data?.results);
    } catch (error) {
    } finally {
      finishLoading();
    }
  };

  const handleClose = (refresh: boolean): void => {
    setAnimateCard(false);
    setTimeout(() => {
      setShowCard(false);
      if (refresh) {
        getMethods();
      }
    }, 700);
  };

  useEffect(() => {
    if (showCard) {
      setAnimateCard(true);
    }
  }, [showCard]);

  useEffect(() => {
    document.title = "Payment Methods";
  }, []);

  return (
    <div className="w-full">
      <TopLoadingBar color="#03bf5f" ref={barRef} height={3} />
      {showCard && (
        <ModalCard
          showCard={showCard}
          handleHideModal={handleClose}
          animateCard={animateCard}
        >
          <MethodModal handleHideModal={handleClose} />
        </ModalCard>
      )}
      <div className="flex justify-between mb-2 px-0.5">
        <h1 className="text-gray-500 text-3xl pt-2 pb-2">Payment methods</h1>
        <button onClick={() => setShowCard(true)} className="button-green">
          New
        </button>
      </div>
      <MethodTable data={methods || []} setData={setMethods} />
    </div>
  );
};
