import { FC } from "react";
import { FaX } from "react-icons/fa6";
import { EmbeddedMethod } from "./EmbeddedMethod";
import { MethodModalProps } from "../../utils/types/props";

export const MethodModal: FC<MethodModalProps> = ({ handleHideModal }) => {
  return (
    <div>
      <div className="p-2">
        <div className="flex justify-end">
          <button
            onClick={() => handleHideModal(false)}
            className="text-gray-500 px-2"
          >
            <FaX />
          </button>
        </div>
        <div className="px-3 mt-2">
          <h2 className="text-2xl text-blue-500">Add payment method</h2>
        </div>
      </div>
      <div className="p-4 pt-2">
        <EmbeddedMethod handleClose={handleHideModal} />
      </div>
    </div>
  );
};
