import { PulseLoader } from "react-spinners";
import { client } from "@hey-api/client-fetch";
import { PropagateLoader } from "react-spinners";
import { SubscriptionService } from "../api-client";
import { useState, ChangeEvent, useEffect } from "react";
import { addTokenInterceptor } from "../utils/interceptor";
import { useAccountInfoStore } from "../store/accountStore";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { handleError, isValidEmail } from "../utils/functions";
import { useSearchParams, useNavigate } from "react-router-dom";

export const LoginRequest = () => {
  const [email, setEmail] = useState<string>("");
  const [emailSent, setEmailSent] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorEmail, setErrorEmail] = useState<string | null>(null);

  const [loadingAccounts, setLoadingAccounts] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { setAccount, accounts, setAccounts } = useAccountInfoStore();

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>): void => {
    setErrorEmail(null);
    setEmail(e.target.value);
  };

  const handleSendRequest = async (): Promise<void> => {
    if (email === "") {
      setErrorEmail("Please enter email");
    } else if (!isValidEmail(email)) {
      setErrorEmail("Email is invalid");
    } else {
      try {
        setIsLoading(true);
        let captcha = null;
        if (executeRecaptcha) {
          const token = await executeRecaptcha("checkEmail");
          if (token) {
            captcha = token;
          }
        }

        await SubscriptionService.accountRequestEmailLink({
          body: {
            email,
            captcha: captcha || "",
          },
        });

        // send email
        setEmailSent(true);
      } catch (err: any) {
        if (err.response?.data) {
          const { error, errors, warning } = err.response.data;

          if (error || errors || warning) {
            handleError(err.response.data);
            return;
          }
        } else {
          handleError(err.message);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getAccount = async (): Promise<void> => {
    try {
      setLoadingAccounts(true);
      addTokenInterceptor(token as string);

      const response = await SubscriptionService.accountsList();

      const results = response.data?.results;

      if (!results || results.length === 0) {
        // error
        debugger;
        navigate("/login");
        return;
      }

      if (results.length > 1) {
        setAccounts(results);
        localStorage.setItem("accounts_subscription", JSON.stringify(results));
      } else {
        setAccount(results[0]);
        localStorage.setItem(
          "account_subscription",
          JSON.stringify(results[0])
        );
        localStorage.setItem("token_subscription", token!);

        navigate("/invoices");
      }
    } catch (error: any) {
      setError("Something went wrong, please try again later.");
    } finally {
      setLoadingAccounts(false);
    }
  };

  const handleAccountSelection = async (e: any): Promise<void> => {
    const selectedAccount = accounts?.find(
      (account) => account.id === e.target.value
    );
    setAccount(selectedAccount);
    localStorage.setItem(
      "account_subscription",
      JSON.stringify(selectedAccount)
    );
    localStorage.setItem("token_subscription", token!);
    navigate("/invoices");
  };

  useEffect(() => {
    document.title = "TradePeg Subscription";
    if (token) {
      getAccount();
    }
  }, []);

  return (
    <div className="flex justify-center items-center p-4 min-h-screen w-full bg-gradient-animation bg-[length:400%] animate-gradient">
      <div className="shadow bg-white p-5 rounded-md h-fit flex flex-col items-center w-[300px] max-w-[300px]">
        <p className="mt-2 mb-3 text-gray-600 font-semibold text-lg">
          TradePeg Subscription
        </p>
        <img
          src="https://cdn.tradepeg.com/assets/img/logo/logo-tradepeg.png"
          alt="logo"
          className="max-w-24 mb-4"
        />
        {token ? (
          <div className="w-[90%]">
            {loadingAccounts ? (
              <div className="flex flex-col item-center justify-center gap-1">
                <p className="text-3xl text-green-600 text-center">
                  Loading...
                </p>
                <div className="flex justify-center h-7 -ml-7 mt-3">
                  <PropagateLoader color="#03c160" size={22} />
                </div>
              </div>
            ) : accounts ? (
              <div className="flex flex-col justify-center gap-1 mt-6 relative">
                <label
                  htmlFor="account"
                  className="text-gray-500 absolute -top-2.5 left-2 bg-white px-1 text-sm"
                >
                  Select account
                </label>
                <select
                  id="account"
                  onChange={handleAccountSelection}
                  className="border border-gray-300 rounded shadow p-2.5 px-1.5"
                >
                  <option></option>
                  {accounts.map((account) => {
                    return (
                      <option key={account.id} value={account.id?.toString()}>
                        {account.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : (
              <p className="text-xs text-red-500">{error}</p>
            )}
          </div>
        ) : !emailSent ? (
          <div>
            {/* <a
              className="bg-emerald-600 hover:bg-green-800 w-full flex justify-center items-center text-white rounded-md disabled:bg-gray-400 p-2 shadow mt-2 text-sm"
              href="https://signin.tradepeg.com/signin?scope=billing"
            >
              Login with your TradePeg account
            </a> 
            <hr className="mt-3 mb-2" />*/}
            <p className="text-base text-gray-600 text-center">
              {/* If you do not have a TradePeg account, you can request a secure
              login link by entering your email address.
              <br /> */}
              <br />
              Please enter your email address <br /> to receive a secure login
              link.
            </p>
            <input
              type="email"
              required
              id="email"
              name="email"
              placeholder="Email address"
              value={email}
              onChange={(e) => {
                handleChangeEmail(e);
              }}
              className={`${
                errorEmail ? "border-red-500" : "border-gray-[#7c879f]"
              } dark:focus:border-blue-500 border-gray-400 dark:border-gray-200 appearance-none mt-3
                bg-transparent rounded-md border-1.5 focus:border-blue-600 peer block shadow leading-10 w-full h-[38px]
                text-md text-gray-800 focus:outline-none focus:ring-0 border pl-1.5`}
            />
            <div>
              {errorEmail && (
                <p className="mt-2 text-red-500 px-1 pb-2 flex gap-1 items-center text-base">
                  {errorEmail}
                </p>
              )}
            </div>
            <button
              className="bg-emerald-600 hover:bg-green-800 w-full flex justify-center items-center text-white rounded-md disabled:bg-gray-400 p-2 shadow mt-2 text-sm"
              onClick={handleSendRequest}
              disabled={isLoading}
            >
              {isLoading ? <PulseLoader color={"white"} /> : "Request link"}
            </button>
          </div>
        ) : (
          <p className="text-gray-500 font-normal text-start">
            <span className="text-lg font-semibold text-black">
              Check your email
            </span>
            <br />
            <br />
            If there is an account associated with{" "}
            <strong className="font-semibold text-black">{email}</strong> you’ll
            get an email with a link to reset your password.
            <br />
            <br />
            If you do not receive an email within a few minutes, please check
            your spam or junk folder.
            <br />
            <br />
            The link will expire after 10 minutes.
          </p>
        )}
      </div>
    </div>
  );
};
