import { AccountInvoice } from "../../api-client";
import { AccountPaymentMethod } from "../../api-client";
export type SubscriptionTableProps = {
  data: AccountInvoice[];
  loading: boolean;
  handleShowModal: (amount: number, reference: string, inv: string) => void;
};

export type CreateIntentBodyType = {
  amount: number;
  currency: string;
  reference: string;
  inv: string;
};

export type MethodModalProps = {
  handleHideModal: (refresh: boolean) => void;
};

export type CheckoutFormProps = {
  handleClose: (refresh: boolean) => void;
  createIntentBody: CreateIntentBodyType;
  methodId: string | undefined;
};

export type EmbeddedMethodProps = {
  handleClose: (refresh: boolean) => void;
};

export type SubscriptionModalProps = {
  handleHideModal: (refresh: boolean) => void;
  createIntentBody: CreateIntentBodyType;
};

export type MethodTableProps = {
  data: AccountPaymentMethod[];
  setData: (value: AccountPaymentMethod[]) => void;
};

export const StripePublicKeyTest = "pk_test_qyTKnwdUGrv3tK1lIGUGRmzx";
export const StripePublicKeyProd = "pk_live_DhXZb5OVHHQfa7yjVdxKBKk6";
