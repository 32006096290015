import Swal from "sweetalert2";

export const swalStyle = {
  allowOutsideClick: false,
  backdrop: false,
  customClass: {
    popup: "pb-2 w-fit",
    modal: "w-fit bg-red-500",
    actions: "!mt-0",
    confirmButton: " !bg-gray-500 border-none !shadow  focus:!shadow py-1",
    cancelButton: "!bg-red-500 border-none !shadow focus:!shadow py-1",
    title: "text-xl font-normal pt-2 px-4",
    htmlContainer: " !text-gray-500 !text-base !mt-1 mb-0",
  },
  showClass: {
    popup: "animate__animated animate__slideInDown animate__faster",
  },
  hideClass: {
    popup: "animate__animated animate__fadeOutUp animate__faster",
  },
};

export const handleError = (response: any): void => {
  const errorMessage = response?.error;
  const errorData = response?.errors;
  const warningData = response?.warnings;

  let errorMessageString = "";
  if (typeof response === "string") {
    errorMessageString = response;
  } else if (errorMessage) {
    errorMessageString = errorMessage;
  } else if (errorData) {
    const errorMessages: string[] = [];

    Object.keys(errorData).forEach((key) => {
      const messages = errorData[key];
      messages.forEach((message: string) => {
        errorMessages.push(`${message}`);
      });
    });

    errorMessageString = errorMessages.join("\n");
  } else if (warningData) {
    const errorMessages: string[] = [];

    Object.keys(warningData).forEach((key) => {
      const messages = warningData[key];
      messages.forEach((message: string) => {
        errorMessages.push(`${message}`);
      });
    });

    errorMessageString = errorMessages.join("\n");
  }

  Swal.fire({
    title: "Something went wrong",
    text: errorMessageString,
    position: "top",
    confirmButtonText: "Close",
    ...swalStyle,
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.close();
    }
  });
};

export const handleSuccess = (
  title: string,
  refresh: boolean = false
): void => {
  Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    customClass: {
      title: "!w-fit",
      popup: "!w-fit",
    },
  })
    .fire({
      icon: "success",
      title,
    })
    .then((result) => {
      if (result.isConfirmed) {
        if (refresh) {
          window.location.reload();
        }
        Swal.close();
      }
    });
};

const moneyFormat = new Intl.NumberFormat("en-GB", {
  style: "decimal",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const asMoney = (input: number | undefined): null | string => {
  if (input != null && input !== undefined) {
    return moneyFormat.format(input);
  }
  return null;
};

export const isValidEmail = (email: string): boolean => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};
