import {
  Column,
  useTable,
  useSortBy,
  usePagination,
  useFlexLayout,
  useResizeColumns,
} from "react-table";
import axios from "axios";
import { PuffLoader } from "react-spinners";
import { FiDownload } from "react-icons/fi";
import { FC, useMemo, useState } from "react";
import { asMoney, handleError } from "../../utils/functions";
import { useAccountInfoStore } from "../../store/accountStore";
import type { SubscriptionTableProps } from "../../utils/types/props";
import { AccountInvoice, SubscriptionService } from "../../api-client";

type AccountInvoiceTable = AccountInvoice & {
  download?: string;
};

export const SubscriptionTable: FC<SubscriptionTableProps> = ({
  data,
  loading,
  handleShowModal,
}) => {
  const { account } = useAccountInfoStore();
  const [loadingId, setLoadingId] = useState<string | null>(null);

  const invoicesColumns: Column<AccountInvoiceTable>[] = useMemo(
    () => [
      { Header: "Number", accessor: "number", width: 110 },
      {
        Header: "Type",
        accessor: (row) => (row.type === "INVOICE" ? "Invoice" : "Credit note"),
        width: 120,
      },
      {
        Header: "Status",
        accessor: (row) =>
          row.paymentStatus
            ? `${row.paymentStatus[0]}${row.paymentStatus
                ?.slice(1)
                .toLowerCase()}`
            : "",
        width: 80,
      },
      { Header: "Date", accessor: "date", width: 100 },
      { Header: "Due Date", accessor: "dueDate", width: 100 },
      {
        Header: "Amount Outstanding",
        accessor: (row) =>
          row.amountOutstanding ? asMoney(row.amountOutstanding) : "",
        width: 140,
      },
      { Header: "Total", accessor: "total", width: 180 },

      {
        Header: "",
        accessor: "download",
        width: 40,
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: invoicesColumns as Column<AccountInvoice>[],
        data,
      },
      useFlexLayout,
      useResizeColumns,
      useSortBy,
      usePagination
    );

  const getPdf = async (
    accountId: string,
    invoiceId: string,
    name: string
  ): Promise<void> => {
    try {
      setLoadingId(invoiceId);

      const baseUrl = "https://api-billing.tradepeg.com";

      const url = `${baseUrl}/subscription/accounts/${accountId}/invoices/${invoiceId}/pdf`;
      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: localStorage.getItem("token_subscription"),
        },
      });

      const href = URL.createObjectURL(response.data);
      let alink = document.createElement("a");
      alink.href = href;
      alink.download = name + ".pdf";
      alink.click();
    } catch (error: any) {
      debugger;
      handleError("Failed to download invoice");
      return;
    } finally {
      setLoadingId(null);
    }
  };

  return (
    <div className="w-full px-1 pb-2 max-h-[90vh] overflow-y-auto bg-white rounded shadow">
      <table {...getTableProps()} className="w-full">
        <thead className="sticky -top-0 z-[4]">
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="bg-white pt-1"
            >
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  {...column.getHeaderProps()}
                  className={`${
                    columnIndex === 0
                      ? "rounded-s-md"
                      : columnIndex === invoicesColumns.length - 1
                      ? "rounded-e-md"
                      : ""
                  } ${columnIndex} text-left p-2 text-xs font-medium text-gray-500 bg-gray-100 relative`}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="bg-white">
          {!loading ? (
            rows.length > 0 ? (
              rows.map((row, rowIndex) => {
                prepareRow(row);

                return (
                  <tr
                    {...row.getRowProps()}
                    className={
                      rows[rowIndex].original.isOverdue
                        ? "text-red-500"
                        : "text-gray-600"
                    }
                  >
                    {row.cells.map((cell) => {
                      const columnId = cell.column.id;
                      const cellRow = cell.row.original;

                      const isMoneyColumn =
                        columnId === "total" ||
                        columnId === "amountPaid" ||
                        columnId === "amountOutstanding";

                      const isDateColumn =
                        columnId === "date" || columnId === "dueDate";

                      return (
                        <td
                          {...cell.getCellProps()}
                          className="p-2 pt-3 pb-3 py-1 whitespace-nowrap overflow-hidden border-b border-gray-300 text-sm"
                          key={columnId}
                        >
                          {columnId === "download" ? (
                            <button
                              onClick={() =>
                                getPdf(
                                  account?.id!,
                                  cellRow.id!,
                                  cellRow.number!
                                )
                              }
                              disabled={loadingId == cellRow.id}
                              className="w-full flex items-center justify-end pr-1.5 text-blue-600"
                            >
                              {loadingId == cellRow.id ? (
                                <PuffLoader color="rgb(37 99 235)" size={20} />
                              ) : (
                                <FiDownload size={20} />
                              )}
                            </button>
                          ) : isDateColumn ? (
                            cell.value ? (
                              <p className="mb-0">
                                {new Intl.DateTimeFormat("en-GB", {
                                  year: "2-digit",
                                  month: "numeric",
                                  day: "numeric",
                                }).format(new Date(cell.value))}
                              </p>
                            ) : null
                          ) : isMoneyColumn ? (
                            <div className="flex gap-2.5 items-center">
                              {asMoney(cell.value)}
                              {columnId === "total" &&
                                cellRow.paymentStatus == "UNPAID" && (
                                  <button
                                    onClick={() =>
                                      handleShowModal(
                                        rows[rowIndex].original
                                          .amountOutstanding!,
                                        cellRow.id as string,
                                        cellRow.number as string
                                      )
                                    }
                                    className="button-pay !py-0.5"
                                  >
                                    Pay
                                  </button>
                                )}
                            </div>
                          ) : (
                            cell.value
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr className="w-full border-b flex items-center justify-center p-2 text-lg text-gray-500">
                <td>
                  <p>No invoices available at the moment.</p>
                </td>
              </tr>
            )
          ) : (
            <tr className="w-full border-b flex items-center justify-center p-2 text-lg text-gray-500">
              <td>
                <p>Loading...</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
