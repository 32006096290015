import { FaX } from "react-icons/fa6";
import { AccountInfo } from "../api-client";
import { useAccountInfoStore } from "../store/accountStore";

export const SwitchAccountModal = ({
  handleHideModal,
}: {
  handleHideModal: () => void;
}) => {
  const { account, setAccount, accounts } = useAccountInfoStore();

  const handleSelectAccount = (account: AccountInfo): void => {
    setAccount(account);
    localStorage.setItem("account_subscription", JSON.stringify(account));
    handleHideModal();
  };

  return (
    <div>
      <div className="flex justify-end">
        <button onClick={handleHideModal} className="text-gray-500 px-2">
          <FaX />
        </button>
      </div>
      <div className="px-3 mt-2">
        <h2 className="text-2xl text-blue-500 text-center">Switch account</h2>
      </div>
      <div className="mt-4 px-3">
        {accounts
          ?.filter((accountAvailable) => accountAvailable.id !== account?.id)
          .map((account) => {
            return (
              <button
                onClick={() => {
                  handleSelectAccount(account);
                }}
                className="border rounded shadow p-2 mb-2 text-sm hover:bg-gray-50 w-full text-left font-lightbold"
              >
                <p>{account.name?.toUpperCase()}</p>
              </button>
            );
          })}
      </div>
    </div>
  );
};
